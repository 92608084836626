import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { createLocalLink } from "../../../utils"

const LeftImage = ({ sectionHeader, paragraph, image, button }) => {
  return (
    <section className="p-0 mb-2">
      <Container>
        <Row className={`flex-column align-items-center flex-md-row `}>
          <Col md={6} className="px-md-0">
            <div>
              <div className={`position-relative  py-5 `}>
                {image && (
                  <GatsbyImage
                    image={getImage(image.localFile)}
                    alt={image.altText}
                    className="w-100 h-100"
                  />
                )}
              </div>
            </div>
          </Col>
          <Col md={6} className="px-md-0">
            <div className={`h-100 pt-4 pl-0 pl-md-5`}>
              <h2 className={`text-left text-uppercase pt-1 mb-4`}>
                {sectionHeader}
              </h2>
              <div
                className={`h4 font-weight-normal text-left`}
                style={{ fontSize: "20px" }}>
                {paragraph}
              </div>
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-center">
          {button && (
            <Button
              href={createLocalLink(button.url)}
              variant="primary"
              size="lg">
              {button.title}
            </Button>
          )}
        </div>
      </Container>
    </section>
  )
}

export default LeftImage
