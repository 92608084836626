import { graphql } from "gatsby"
import React from "react"

import LeftImage from "./LeftImage"

export const fragment = graphql`
  fragment LeftImageFragment on WpPage_Flexlayouts_FlexibleLayouts_LeftImage {
    sectionHeader
    paragraph
    image {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 660
            quality: 75
            placeholder: NONE
          )
        }
      }
    }
    button {
      title
      url
    }
  }
`

export const ACFLeftImage = ({ sectionHeader, paragraph, image, button }) => (
  <LeftImage
    sectionHeader={sectionHeader}
    paragraph={paragraph}
    image={image}
    button={button}
  />
)
